
/**
* 柱状图组件
* @ prop data {

* }
*/
<template>
  <div class="line-chart-panel">
    <div class="chart-title">{{ optionData.title }}</div>
    <div class="line-chart" ref="barChartRef"></div>
    <div class="legend">
        <div>{{ optionData.legend[0] }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BarChart",
  props:['optionData'],
  data() {
    return {
      myChart:undefined,
      option:{
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.70)',
          textStyle:{
            fontSize: 12,
            color: '#FFFFFF',
            fontWeight: 600,
          },
          borderWidth:0,
          axisPointer: {
            type: 'shadow',
            z:1,
            shadowStyle: {
              color: 'rgba(233,233,237,0.50)',
              width: '1'
            }
          },
          formatter: function(params) {
            let dotLable = (idx) => {
              return `<span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 2px;background-color:${params[idx].color}"></span><span style="margin-right:5px">${params[idx].seriesName}</span>`
            }
            let result = `
              <div>
                <div>${params[0].axisValue}</div>
                <div style="display: flex;justify-content: space-between;"><div>${dotLable(0)}</div><div>${params[0].data || 0}辆</div></div>
              </div>
            `
            return result
          }
        },
        grid:{
          show:false,
          top:'40px',
          right:'5%',
          bottom:'45px',
          left:'12%'
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLine: {
            show: true,
            lineStyle:{color: "#E9E9ED"},
          },
          //x轴刻度线设置
          axisTick: {
            show: true,
            textStyle: {
                color:'#9798AB'
            }
          },
          axisLabel:{ 
            interval:0 ,     // 设置横坐标的文字显示间隔,0全部显示
            textStyle: {
                color:'#9798AB'
            },
          }      
        },
        yAxis: [
          {
            // name:'s',
            nameTextStyle:{
              color:'#9798AB'
            },
            type: 'value',
            min: 0,
            // max: 250,
            // interval: 50,
            minInterval: 1,//y轴的刻度只显示整数
            axisLabel:{
              textStyle: {
                color:'#9798AB'
              },
              padding:[0,2,0,2]
            },
            axisLine: {
              show: true,
              lineStyle:{
                color: "#E9E9ED",
                shadowOffsetY:-10,      //利用阴影进行反向延长
					      shadowColor: '#E9E9ED',   //设置阴影的颜色
              },
            },
           
          }
        ],
        series: [
          {
            type: 'bar',
            data:  [
              4.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
            ],
            itemStyle:{
              color:'#436EFF'
            },
            barWidth:'15px',
            emphasis: {
              disabled: true,
              focus: 'none'
            },
          },
        ]
      }
    };
  },
  watch:{
    optionData:{
      handler(newVal) {
        this.setBarData()
      },
      deep:true,
      immediate:true
    }
  },
  created: function () {
    
  },
  mounted() {
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.resize);
  },
  methods: {
   setBarData(){
    const {xAxisData, unit,data,legend} =  this.optionData
    if(xAxisData.length  ){
      this.option.xAxis.data = xAxisData
      this.option.yAxis[0].name = unit,
      this.option.series[0].data = data
      this.option.series[0].name =legend[0]
    }
    if(!this.myChart){
      setTimeout(() => {
        this.myChart = this.$echarts.init(this.$refs.barChartRef);
        this.myChart.setOption(this.option);
      },300)
    } else {
      this.myChart.setOption(this.option);
    }
   },
   resize(){
    this.myChart.resize()
   } 
  },
};
</script>

<style lang="scss" scoped>
.line-chart-panel{
  position: relative;
  width: 100%;
  padding-bottom: 24px;
  .chart-title{
    font-size: 14px;
    color: #000000;
    font-weight: 600;
    padding-left: 18px;
  }
  .line-chart{
    width: 100%;
    height: 260px;
  }
  .legend{
    display: flex;
    justify-content: center;
    margin-top: 5px;
    div{
      position: relative;
      // margin-right: 34px;
      font-size: 12px;
      color: #000000;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  .legend div:first-child::before{
    content: '';
    position: absolute;
    top: 5px;
    left: -18px;
    width: 14px;
    height: 8px;
    background: #436EFF;
  }
}
</style>
