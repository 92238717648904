
/**
* 环形饼图组件
* @ prop data {
*    adDriveDistanceVO: 图表的option属性
  {
    adDistance       AD驾驶里程
    adPercent        AD驾驶里程占比
    sdDistance       SD驾驶里程
    sdPercent        SD驾驶里程占比
    totalDistance    总里程
  }
  xAxisData:X轴 
* }
*/
<template>
  <div class="pie-chart"> 
    <div class="pie-calendar">
      <div 
      v-for="(date,idx) in xAxisData" 
      :key="'pie_chart_'+idx"
      :style="{width: 100/xAxisData.length+'%'}" 
      :class="{'active':active === idx}"
      @click="active = idx"
      v-html="date">
    </div>
    </div>
    <div class="chart-panel"> 
      <div class="chart-content">
        <div class="donut-chart" ref="pieChartRef"></div>
        <div class="center-data">
          <p class="total-mileage">{{ optionData.totalDistance || 0 }}<span class="unit">km</span></p>
          <p class="total-mileage-label">总里程</p>
        </div>
      </div>
      <div class="legend"> 
        <div class="legend-item">
          <div class="label ad">AD驾驶里程</div>
          <div class="value">
            <span class="distance">{{ optionData.adDistance  || 0}}<span class="unit">km</span></span>
            <span class="persent">{{ optionData.adPercent  || 0}}<span class="unit">%</span></span>
          </div>
        </div>  
        <div class="legend-item" > 
          <div class="label sd">SD驾驶里程</div>
          <div class="value">
            <span class="distance">{{ optionData.sdDistance || 0 }}<span class="unit">km</span></span>
            <span class="persent">{{ optionData.sdPercent || 0 }}<span class="unit">%</span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "PieChart",
  props:['xAxisData','adDriveDistanceVO'],
  data() {
    return {
      myChart:undefined,
      dataIndex:0,
      option:{
        color:['#436EFF','#11CB74',],
        series: [
          {
            name: 'AD',
            type: 'pie',
            radius: ['52%', '80%'],
            label: {
              show: false,
              position: 'center'
            },
            silent:true,                // 取消点击图表高亮效果
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 5
            },
            data: []
          }
        ]
      },
      optionData:[],        // 当前数据
      active:0,         // 当前激活的日期下标
    };
  },
  computed:{
    refreshFilter(){
      return {
        adDriveDistanceVO:this.adDriveDistanceVO,
        active:this.active
      }
    }
  },
  watch: {
    adDriveDistanceVO:{
      handler: function (newVal) {
        if(newVal){
          if(this.active != 0){
            this.active = 0
          }
        }
      },
      immediate: false,
      deep:true
    },
    refreshFilter:{
      handler(){
       this.setPieData()
      },
      immediate:true,
      deep:true
    },
  },

  created: function () {
    
  },
  mounted() {
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    setPieData(){
      const optionData =  this.adDriveDistanceVO.length>0 ? this.adDriveDistanceVO[this.active]:{adDistance:0,sdDistance:0}
      this.option.series[0].data = [optionData.adDistance,optionData.sdDistance]
      if((optionData.adDistance != 0 && optionData.sdDistance == 0) || (optionData.adDistance == 0 && optionData.sdDistance != 0)){
        this.option.series[0].itemStyle.borderWidth = 0
      } else {
        this.option.series[0].itemStyle.borderWidth = 5
      }
      this.optionData = optionData
      if(!this.myChart){
      setTimeout(() => {
          this.myChart = this.$echarts.init(this.$refs.pieChartRef);
          this.myChart.setOption(this.option);
        },300)
      } else {
        this.myChart.setOption(this.option);
      }
    },
    resize(){
      this.myChart.resize()
    }
  },
};
</script>

<style lang="scss" scoped>
.pie-chart{
  .pie-calendar{
    display: flex;
    padding: 0 2px;
    div{
      font-size: 12px;
      color: #9798AB;
      letter-spacing: 0;
      text-align: center;
      font-weight: 600;
      white-space: pre-wrap; 
    }
    .active{
      color: #436EFF;
    }
  }
  .chart-panel{
  position: relative;
  display: flex;
  width: 100vw;
  height: 180px;
  .chart-content{
    position: relative;
    width: 43.2vw;
    height: 100%;
    .donut-chart{
      width:  100%;
      height: 100%;
    }
    .center-data{
      position: absolute;
      width: 100%;
      left: 50%;
      top: 53%;
      transform: translate(-50%,-50%);
      font-family: PingFangSC-Semibold;
      text-align: center;
      font-weight: 600;
      color: #000000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .total-mileage{
        font-size: 16px;
        color: #000000;
        text-align: center;
        font-weight: 700;
        span{
          font-size: 12px;
        }
      }
      .total-mileage-label{
        font-size: 12px;
        color: #9798AB;
        font-weight: 600;
      }
   
    }
  }
  .legend{
    width: 50vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    padding-top: 13px;
    .total-mileage{
      font-size: 16px;
      margin-bottom: 10px;
    }
    .legend-item{
      width: 44.53vw;
      height: 56px;
      background: #F7F7FF;
      border-radius: 4px;
      margin-bottom: 12px;
      padding: 6px 12px 0 7px;
      .label{
        position: relative;
        padding-left: 20px;
        &::before{
          content: '';
          position: absolute;
          top: 5px;
          left: 5px;;
          width: 10px;
          height: 10px;
          background: #436EFF;
          border-radius: 2px;
        }
      }
      .sd::before{
          content: '';
          position: absolute;
          top: 5px;
          left: 5px;;
          width: 10px;
          height: 10px;
          background: #11CB74;
          border-radius: 2px;
      }
      .value{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: #000000;
        font-weight: 700;
        padding-left: 20px;
        padding-right: 10px;
      }
    }

  }
  .unit{
    font-size: 11px;
    margin-left: 1px;
  }
 
 }
}
</style>
