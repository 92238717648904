/**
 * 核心数据
 * @ prop data {
  currentFilter:当前筛选条件
 * }
 * @ methods {

 * }
 */
<template>
  <div class="core-data">
    <div class="core-data-content">
      <div class="title">
        <span>{{ coreDataTilte }}</span>
        <div class="question-mark" @click="isGlossary = true"><img src="../../../assets/app/dataCenter/question-mark@2x.png" /></div>
      </div>
      <div class="current-data">
        <div class="item">
          <p class="value">{{ firstDate.numberOfVehicles | filterValue() }}</p>
          <p class="lable">出车数(辆)</p>
        </div>
        <div class="item">
          <p class="value">{{ shiftsAndPieces(firstDate) }}</p>
          <p class="lable">{{ businessType === 1?'班次(趟)':'送件数(件)' }}</p>
        </div>
        <div class="item">
          <p class="value">{{ orderQuantityAndDeliveryRate(firstDate,false) }}</p>
          <p class="lable">{{ businessType === 1?`单量(${firstDate.courierCount>10000?'万单':'单'})`:'妥投率(%)' }}</p>
        </div>
      </div>
      <div class="table-data" :class="{'table-data-expand':isArrowDown}">
        <ul>
          <li v-for="(data,idx) in overviewList" :key="'calendar_'+idx" :style="{width: 100/overviewList.length+'%'}">
            <div class="th">{{ data.th }}</div>
            <div class="td">{{ data.numberOfVehicles | filterValue('辆') }}</div>
            <div class="td">{{ shiftsAndPieces(data,true)}}</div>
            <div class="td">{{ orderQuantityAndDeliveryRate(data,true) }}</div>
          </li>
        </ul>
      </div>
      <div class="arrow-down" @click="arrowDownClick"><img :class="{'arrow-down-rotate':isArrowDown}" src="../../../assets/app/dataCenter/dropDown@2x.png" /></div>
    </div>
    <glossary :isShow.sync="isGlossary" :glossaryKey="glossaryKey"></glossary>
  </div>
</template>

<script>
import Glossary from './Glossary.vue'
import { querOverviewList } from '@/api/api';
import {keepTwoDecimal} from '../../../utils/index'
export default {
  props: ['currentFilter','dataFilter'],
  components:{
    Glossary,
  },
  data() {
    return {
      isArrowDown:false,
      overviewList:[],
      firstDate:{},
      isGlossary:false,
    };
  },
  computed:{
    businessType(){
      return this.currentFilter.businessType
    },
    dateActive(){
      return this.currentFilter.dateFilter.active
    },
    coreDataTilte(){
      if(this.dateActive === 1){
        return '当日核心数据总览'
      } else  if(this.dateActive === 2){
        return '上周核心数据总览'
      } else  if(this.dateActive === 3){
        return '上月核心数据总览'
      }
    },
    glossaryKey(){
      return this.businessType === 1?'receive':'delivery'
    }
  },
  filters:{
    filterValue: function (val,unit) {
      if(val || val == 0) {
        return `${val}${unit?unit:''}`
      }
      return '-' 
    }
  },
  watch: {
    dataFilter:{
      handler(){
        this.getOverviewList()
      },
      immediate:false,
      deep:true
    }
  },
  created() {

  },

  mounted(){

  },

  methods: {
    arrowDownClick(){
      this.isArrowDown = !this.isArrowDown
    },
    getOverviewList(){
      if(!this.currentFilter.addressFilter.city) return
      querOverviewList(this.dataFilter).then(res => {
        let dataArr = []
        Object.keys(res).forEach((key,idx) => {
          if(idx === 0) {
            this.firstDate =  res[key]
          } else {
            dataArr.push({
              th:key,
              ...res[key]
            })
          }
        })
        this.overviewList = dataArr
      })
    },
    // 接驳下的班次/配送下的件数 数据二次处理
    shiftsAndPieces(item,isShowUnit){
      if(this.businessType === 1){
        return (item.numberOfFlights || item.numberOfFlights== 0) ? `${item.numberOfFlights}${isShowUnit?'趟':''}` : '-'
      } else {
        return (item.packagesCount || item.packagesCount == 0 )? `${item.packagesCount}${isShowUnit?'件':''}` : '-'
      }
    },
    // 接驳下的单量/配送下的妥投率 数据二次处理
    orderQuantityAndDeliveryRate(item,type){
      if(this.businessType === 1){
        if(item.courierCount > 10000){
          // 以万为单位,保留两位小数
          return  keepTwoDecimal(item.courierCount/10000)+(type?'万单':'')
        } else  if(item.courierCount > 0){
          return  item.courierCount+(type?'单':'')
        } else {
          return  '-'
        }
       
      } else {
        const unit = type?'%':''
        // 乘以100保留两位小数
        return (item.signRate || item.signRate == 0 )? keepTwoDecimal(item.signRate*100)+unit : '-'
      }
    }

  },
};
</script>

<style scoped lang="scss"> 
.core-data{
  padding: 0 10px;
  &-content{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(60,64,70,0.04);
    border-radius: 8px;
    .title{
      position: relative;
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 14px;
        margin-bottom: 8px;
        font-size: 16px;
        color: #000000;
        text-align: center;
        font-weight: 600;
      }
      .question-mark{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(127%,-32%);
        width: 50px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 4px;
        img{
          width: 16px;
          height: 16px;
        }
      }
    }
    .current-data{
      width: 100%;
      height: 64px;
      padding: 0 10px;
      display: flex;
      margin-bottom: 12px;
      .item{
        position: relative;
        width: 33.33%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &::after{
          content: '';
          position: absolute;
          right: 0;
          top: 10px;
          width: 1px;
          height: 44px;
          background: #F1F1F1;
        }
        .value{
          font-size: 24px;
          color: #000000;
          font-weight: 700;
          margin-bottom: 2px;
        }
        .lable{
          font-size: 12px;
          color: #000000;
          text-align: center;
          font-weight: 400;
        }
        
      }
      .item:last-child::after{
        content: '';
        width:0;
      }
    }
    .table-data{
      width: 100%;
      height: 0;
      transition: all .3s ease ;
      overflow:hidden;
      ul{
        width: inherit;
        display: flex;
        height: inherit;
        li{
          width: calc((100%)/6);
          .th{
            font-size: 12px;
            color: #436EFF;
            letter-spacing: 0.2px;
            text-align: center;
            font-weight: 600;
            margin-bottom: 4px;
          }
          .td{
            font-size: 12px;
            color: #000000;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
            margin-bottom: 4px;
          }
        }
      }
    }
    .table-data-expand{
      height: 80px;
    }
    .arrow-down{
      display: flex;
      width: 60px;
      height: 24px;
      padding-bottom: 8px;
      margin: auto;
      margin-top: 15px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .arrow-down-rotate{
      transform: rotate(180deg);
    }
  }
}
</style>
