/**
 * 图表数据
 * @ prop data {
  currentFilter:当前筛选条件
  dataFilter: 组装好的接口请求条件
 * }
 * @ methods {

 * }
 */
<template>
  <div class="chart-content">
    <div class="modal-data m-b-15">
      <div class="modal-title">
        <span>时效数据</span>
        <div class="question-mark" @click="questionMarkClick('agingData')"><img src="../../../assets/app/dataCenter/question-mark@2x.png" /></div>
      </div>
      <!-- 响应时长 -->
      <mixed-line-bar :optionData="responseTime"></mixed-line-bar>
      <div class="gap-line"></div>
      <!-- 挪车响应时长 -->
      <mixed-line-bar :optionData="moveResponseTime"></mixed-line-bar>
      <div class="gap-line"></div>
      <!-- 任务时速 -->
      <mixed-line-bar :optionData="taskSpeed"></mixed-line-bar>
      <div class="gap-line"></div>
      <!-- 行驶平均速度 -->
      <mixed-line-bar :optionData="averageSpeed"></mixed-line-bar>
    </div>
    <div class="modal-data m-b-15">
      <div class="modal-title">
        <span>里程数据</span>
        <div class="question-mark" :adDriveDistanceVO="adDriveDistanceVO" @click="questionMarkClick('mileageData')" ><img src="../../../assets/app/dataCenter/question-mark@2x.png" /></div>
      </div>
      <!-- 行驶里程 -->
      <mixed-line-bar :optionData="drivenDistance"></mixed-line-bar>
    </div>
    <div v-if="isHasADPermissions" class="modal-data m-b-15">
      <div class="modal-title">
        <span>AD数据</span>
        <div class="question-mark" @click="questionMarkClick('adData')"><img src="../../../assets/app/dataCenter/question-mark@2x.png" /></div>
      </div>
      <!-- 行驶里程 -->
      <pie-chart :xAxisData="xAxisData" :adDriveDistanceVO="adDriveDistanceVO"></pie-chart>
      <bar-chart :optionData="adVehicleNumVO" ></bar-chart>
    </div>
    <glossary :isShow.sync="isGlossary" :glossaryKey="glossaryKey"></glossary>
  </div>
</template>

<script>
import BarChart from './BarChart.vue';
import MixedLineBar from './MixedLineBar.vue';
import PieChart from './PieChart.vue';
import Glossary from './Glossary.vue'
import { queryDataCenterList,getRoleProductInfo } from '@/api/api';
import { setDayXAxisData, setWeekXAxisData, setMonthXAxisData } from "../../../utils/index";

export default {
  components: {
    MixedLineBar,
    PieChart,
    BarChart,
    Glossary,
  },
  props: ['currentFilter','dataFilter'],
  data() {
    return {
      responseTime:{
        title:'响应时长',
        legend:['最快响应时长', '最慢响应时长', '平均'],
        legendAvg:'平均响应时长',
        unit:'s',
        xAxisData:[],
        maxList:[],
        minList:[],
        avgList:[],
      },
      moveResponseTime:{
        title:'挪车响应时长',
        legend:['最快响应时长', '最慢响应时长', '平均'],
        legendAvg:'平均响应时长',
        unit:'s',
        xAxisData:[],
        maxList:[],
        minList:[],
        avgList:[],
      },
      taskSpeed:{
        title:'任务时速',
        legend:['最高任务时速', '最低任务时速', '平均'],
        legendAvg:'平均任务时速',
        unit:'km/h',
        xAxisData:[],
        maxList:[],
        minList:[],
        avgList:[],
      },
      averageSpeed:{
        title:'行驶平均时速',
        legend:['最高行驶时速', '最低行驶时速', '平均'],
        legendAvg:'平均行驶时速',
        unit:'km/h',
        xAxisData:[],
        maxList:[],
        minList:[],
        avgList:[],
      },
      drivenDistance:{
        title:'行驶里程',
        legend:['单车最高', '单车最低', '平均'],
        legendAvg:'单车平均',
        unit:'km',
        xAxisData:[],
        maxList:[],
        minList:[],
        avgList:[],
      },
      adDriveDistanceVO:[],         // AD-行驶里程
      adVehicleNumVO:{              // AD-启动车数
        title:'AD启动车数',
        legend:['AD占比不为0的车数'],
        unit:'辆',
        xAxisData:[],
        list:[]
      },
      isGlossary:false,             // 名词解释
      glossaryKey:'agingData',
      isHasADPermissions:false,     // 判断是否有AD权限
    };
  },
  computed:{
    businessType(){
      return this.currentFilter.businessType
    },
    dateActive(){
      return this.currentFilter.dateFilter.active
    },
    xAxisData(){
      let xAxisData = []
      if(this.dateActive === 1){
        xAxisData = setDayXAxisData(this.currentFilter.dateFilter.currentDayDate)
      } else if(this.dateActive === 2){
        xAxisData =  setWeekXAxisData(this.currentFilter.dateFilter.currentWeekDate)
      } else if(this.dateActive === 3){
        xAxisData =  setMonthXAxisData(this.currentFilter.dateFilter.currentMonth)
      }
      return xAxisData
    },
  },
  watch: {
    dataFilter:{
      handler(){
        this.getDataCenterList()
      },
      immediate:false,
      deep:true
    }
  },
  created() {
    this.getRoleProductInfoServe()
  },

  mounted(){

  },

  methods: {
    questionMarkClick(val){
      this.glossaryKey = val
      this.isGlossary = true
    },
    // 获取表格数据
    getDataCenterList(){
      if(!this.currentFilter.addressFilter.city) return
      // console.log(this.dataFilter)
      this.$loadingCircle.start();
      queryDataCenterList(this.dataFilter).then( res => {
        this.setChartData(res)
      }).finally( _ => {
        this.$loadingCircle.end();
      }) 
    },
    setChartData(res){
      const {responseTimeVO,missionSpeedVO,vehicleSpeedVO,driveDistanceVO,adDataVO,moveResponseTimeVO} = res

      // 响应时长
      this.responseTime.xAxisData = this.xAxisData
      this.responseTime.maxList = responseTimeVO.maxList
      this.responseTime.minList = responseTimeVO.minList
      this.responseTime.avgList = responseTimeVO.avgList

      // 挪车响应时长
      this.moveResponseTime.xAxisData = this.xAxisData
      this.moveResponseTime.maxList = moveResponseTimeVO.maxList
      this.moveResponseTime.minList = moveResponseTimeVO.minList
      this.moveResponseTime.avgList = moveResponseTimeVO.avgList

      // 任务时速
      this.taskSpeed.xAxisData = this.xAxisData
      this.taskSpeed.maxList = missionSpeedVO.maxList
      this.taskSpeed.minList = missionSpeedVO.minList
      this.taskSpeed.avgList = missionSpeedVO.avgList

      // 行驶平均时速
      this.averageSpeed.xAxisData = this.xAxisData
      this.averageSpeed.maxList = vehicleSpeedVO.maxList
      this.averageSpeed.minList = vehicleSpeedVO.minList
      this.averageSpeed.avgList = vehicleSpeedVO.avgList

      // 行驶里程
      this.drivenDistance.xAxisData = this.xAxisData
      this.drivenDistance.maxList = driveDistanceVO.maxList
      this.drivenDistance.minList = driveDistanceVO.minList
      this.drivenDistance.avgList = driveDistanceVO.avgList
      // AD行驶里程
      this.adDriveDistanceVO = adDataVO.adDriveDistanceVO
      // AD启动车数
      this.adVehicleNumVO.xAxisData = this.xAxisData
      this.adVehicleNumVO.data = adDataVO.adVehicleNumVO
    },
    // 动态配置AD权限
    getRoleProductInfoServe(){
      getRoleProductInfo({}).then(res => {
        if(res && res.length > 0){
          const menu = res[0].menuJson
          this.getDefineMunu(220,menu)
        }
      })
    },
      /**
     * 递归查询指定菜单
     * @param {*} id  
     * @param {*} arr 
     */
     getDefineMunu(id,arr){
      arr.forEach((item) => {
        if(item.id === id && item.check){
          this.isHasADPermissions = true
          return 
        } else if(item.children){
          this.getDefineMunu(id,item.children)
        }
      })
    },
  },
};
</script>

<style scoped lang="scss"> 
.chart-content{
  padding: 15px 10px 0 10px;
  -webkit-overflow-scrolling: touch; 
  .modal-data{
    width: 100%;
    background: white;
    box-shadow: 0px 2px 10px 0px rgba(60,64,70,0.04);

    border-radius: 8px;
    .modal-title{
      width: inherit;
      height: 52px;
      display: flex;
      align-items: center;
      span{
        position: relative;
        padding-left: 25px;
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        &::before{
          content: '';
          position: absolute;
          top: 4px;
          left: 16px;
          width: 3px;
          height: 16px;
          background: #436EFF;
        }
      }
      .question-mark{
        width: 50px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 4px;
        img{
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .gap-line{
    // width: 100%;
    height: 1px;
    background: #EBEBEB;
    margin: auto;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 24px;

  }
  .m-b-15{
    margin-bottom: 15px;
  }
}
</style>
