
/**
* 折柱混合组件
* @ prop data {

* }
*/
<template>
  <div class="line-chart-panel">
    <div class="chart-title">{{ optionData.title }}</div>
    <div class="line-chart" ref="lineChartRef"></div>
    <div class="legend">
        <div>{{ optionData.legend[0] }}</div>
        <div>{{ optionData.legend [1]}}</div>
        <div><span class="legend-icon"></span><span>{{ optionData.legend[2] }}</span></div>
    </div>
  </div>
</template>
<script>
 
export default {
  name: "LineBarChart",
  props:['optionData'],
  data() {
    return {
      myChart:undefined,
      option:{
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.70)',
          textStyle:{
            fontSize: 12,
            color: '#FFFFFF',
            fontWeight: 600,
          },
          borderWidth:0,
          padding:[8,10],
          axisPointer: {
            type: 'shadow',
            z:1,
            shadowStyle: {
              color: 'rgba(233,233,237,0.50)',
              width: '1'
            }
          },
          formatter: undefined,
          extraCssText:'z-index:2'
        },
        grid:{
          show:false,
          top:'40px',
          right:'5%',
          bottom:'45px',
          left:'12.5%'
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLine: {
            show: true,
            lineStyle:{color: "#E9E9ED"},
          },
          //x轴刻度线设置
          axisTick: {
            show: true,
            textStyle: {
                color:'#9798AB'
            }
          },
          axisLabel:{ 
            interval:0 ,     // 设置横坐标的文字显示间隔,0全部显示
            textStyle: {
                color:'#9798AB'
            },
          }      
        },
        yAxis: [
          {
            nameTextStyle:{
              color:'#9798AB'
            },
            type: 'value',
            min: 0,
            // max: 250,
            // interval: 50,
            axisLabel:{
              textStyle: {
                color:'#9798AB'
              },
              padding:[0,2,0,2]
            },
            axisLine: {
              show: true,
              lineStyle:{
                color: "#E9E9ED",
                shadowOffsetY:-10,      //利用阴影进行反向延长
					      shadowColor: '#E9E9ED',   //设置阴影的颜色
              },
            },
           
          }
        ],
        // dataZoom: [
        //   {
        //     type: "inside", //slider表示有滑动块的，inside表示内置的
        //     show: false,
        //     xAxisIndex: 0,
        //     start: 0,
        //     end:55,
        //     // type: 'inside', // 可以手动缩放
        //     // preventDefaultMouseMove: false // canvas可以上下滑动
        //   },
        // ],
        series: [
          {
            type: 'bar',
            data:  [],
            itemStyle:{
              color:'#436EFF'
            },
            barWidth:'15px',
            emphasis: {
              disabled: true,
              focus: 'none'
            },
          },
          {
            type: 'bar',
            data:[],
            itemStyle:{
              color:'#D1DCFF'
            },
            barWidth:'15px',
            emphasis: {
              disabled: true,
              focus: 'none'
            },
          },
          {
            type: 'line',
            symbol: 'circle',   //将小圆点改成实心 不写symbol默认空心
            symbolSize: 5,    //小圆点的大小
            data: [],
            itemStyle:{
              color:'#FA685B'
            }
          }
        ]
      }
    };
  },
  watch:{
    optionData:{
      handler(newVal) {
       this.setOptionData()
      },
      deep:true,
      immediate:true
    }
  },
  created: function () {
    this.option.tooltip.formatter = function(params) {
      return this.setTooltipFormatter(params)
    }.bind(this)
  },
  mounted() {
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    // 自定义tooltip
   setTooltipFormatter(params){
    let dotLable = (idx) => {
        return `<span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 2px;background-color:${params[idx].color}"></span><span style="margin-right:5px">${idx === 2?this.optionData.legendAvg:params[idx].seriesName}</span>`
      }
      let result = `
        <div>
          <div>${params[0].axisValue}</div>
          <div style="display: flex;justify-content: space-between;"><div>${dotLable(0)}</div><div>${params[0].data || 0}${this.optionData.unit}</div></div>
          <div style="display: flex;justify-content: space-between;"><div>${dotLable(1)}</div><div>${params[1].data || 0}${this.optionData.unit}</div></div>
          <div style="display: flex;justify-content: space-between;"><div>${dotLable(2)}</div><div>${params[2].data || 0}${this.optionData.unit}</div></div>
        </div>
      `
      return result
   },
   setOptionData(){
    const {xAxisData, unit,maxList,minList,avgList,legend} =  this.optionData
    if(xAxisData.length  ){
      this.option.xAxis.data = xAxisData
      this.option.yAxis[0].name = unit,
      this.option.series[0].data = maxList
      this.option.series[1].data = minList
      this.option.series[2].data = avgList

      for(let i = 0;i<3;i++) {
        this.option.series[i].name =legend[i]
      }
    }
    // this.$nextTick(() => {
    //   if(!this.myChart){
    //     this.myChart = this.$echarts.init(this.$refs.lineChartRef);
    //   }
    //   this.myChart.setOption(this.option);
    // })
    if(!this.myChart){
      setTimeout(() => {
        this.myChart = this.$echarts.init(this.$refs.lineChartRef);
        this.myChart.setOption(this.option);
      },300)
    } else {
      this.myChart.setOption(this.option);
    }
   },
   resize(){
    this.myChart.resize()
   }
    
  },
};
</script>

<style lang="scss" scoped>
.line-chart-panel{
  position: relative;
  width: 100%;
  padding-bottom: 24px;
  .chart-title{
    font-size: 14px;
    color: #000000;
    font-weight: 600;
    padding-left: 18px;
  }
  .line-chart{
    width: 100%;
    height: 260px;
  }
  .legend{
    display: flex;
    justify-content: center;
    margin-top: 5px;
    padding-left: 18px;
    div{
      position: relative;
      margin-right: 34px;
      font-size: 12px;
      color: #000000;
      letter-spacing: 0;
      font-weight: 400;
      
    }
    .legend-icon{
      position: absolute;
      top: 7px;
      left: -18px;
      width: 14px;
      height: 2px;
      background: #FA685B;
      &::before{
        content: '';
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        left: 4px;
        width: 6px;
        height: 6px;
        background: #FA685B; 
        border-radius: 50%;
      }
    }
  }
  .legend div:first-child::before{
    content: '';
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    left: -18px;
    width: 14px;
    height: 8px;
    background: #436EFF;
  }
  .legend div:nth-child(2)::before{
    content: '';
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    left: -18px;
    width: 14px;
    height: 8px;
    background: #D1DCFF;  
  }
  .legend div:nth-child(3){
    margin-right: 0;
  }
}
</style>
