/** * 日报/周报/月报 tab切换组件 * @ prop data { * } * @ methods { *
confirm:日期选中回调 * } */
<template>
  <div class="date-list">
    <div class="date-tab">
      <div
        v-for="(item, idx) in dateArr"
        class="date-panel"
        :class="{ 'date-active': active === item.value }"
        :key="`date_${idx}`"
        @click="dateClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <day-filter
      :isShow.sync="isShowDayFilter"
      :currentDate="currentDayDate"
      @confirm="onConfirmDay"
    ></day-filter>
    <week-filter
      :isShow.sync="isShowWeekFilter"
      :currentWeekDate="currentWeekDate"
      @confirm="onConfirmWeek"
    ></week-filter>
    <month-filter
      :isShow.sync="isShowMonthFilter"
      :currentMonthDate="currentMonth.date"
      @confirm="onConfirmMonth"
    ></month-filter>
  </div>
</template>

<script>
import moment from 'moment'
import DayFilter from "./DayFilter.vue";
import WeekFilter from "./weekFilter.vue";
import MonthFilter from "./MonthFilter.vue";
import { getWeekTime, getDateByDate, getLastMonth } from "../../../utils/index";

// 打开默认选中上一周的
const today = new Date();
const lastWeekDay = getDateByDate(today, -7);
const lastMonth = getLastMonth(today);
export default {
  props: ['dateFilter'],
  components: {
    DayFilter,
    WeekFilter,
    MonthFilter,
  },
  data() {
    return {
      dateArr: [
        { name: "日报", value: 1, type: "Day" },
        { name: "周报", value: 2, type: "Week" },
        { name: "月报", value: 3, type: "Month" },
      ],
      active: 1,
      isShowDayFilter: false,
      currentDayDate: today,
      isShowWeekFilter: false,
      currentWeekDate:  getWeekTime(lastWeekDay), // 最后一周的日期
      isShowMonthFilter: false,
      currentMonth: lastMonth,
    };
  },
  computed: {
    dataValue() {
      if (this.active === 1) {
        return moment(this.currentDayDate).format('MM月DD日') 
      } else if (this.active === 2) {
        return this.formatWeekDate(this.currentWeekDate);
      } else if (this.active === 3) {
        return this.currentMonth.dateFormat;
      }
    },
  },
  watch: {
    dateFilter:{
      handler(newVal){
        // 修改后的值
        this.active = newVal.active,
        this.currentDayDate = newVal.currentDayDate,
        this.currentWeekDate = newVal.currentWeekDate,
        this.currentMonth = newVal.currentMonth
      },
      immediate:false,
      deep:true
    },
    dataValue:{
      handler(){
        this.$emit('change',{
          active:this.active,
          currentDayDate:this.currentDayDate,
          currentWeekDate:this.currentWeekDate,
          currentMonth:this.currentMonth,
          dataValue:this.dataValue
        })
      },
      immediate:true
    }
  },
  created() {
    
  },

  mounted() {

  },

  methods: {
    dateClick(tab) {
      this.active = tab.value;
      this.$emit('tabChange',this.active)
    },
    dateChangeClick() {
      const tab = this.dateArr.find((item) => item.value === this.active);
      this[`isShow${tab.type}Filter`] = true;
    },
    onConfirmDay(date) {
      this.currentDayDate = date;
    },
    onConfirmWeek(date) {
      this.currentWeekDate = date;
    },
    onConfirmMonth(month) {
      this.currentMonth = month;
    },
    //  一周的开始结束日期  1月23日-29日
    formatWeekDate(currentWeekDate) {
      const end = currentWeekDate[currentWeekDate.length - 1];
      const star = currentWeekDate[0];
      if (star.getMonth() === end.getMonth()) {
        return `${moment(star).format('MM月DD日')}~${end.getDate()}日`;
      } else {
        return` ${moment(star).format('MM月DD日')}~${moment(end).format('MM月DD日')}` ;
      }
      // return ` ${moment(star).format('MM.DD')}-${moment(end).format('MM.DD')}` ;
    },
  },
};
</script>

<style scoped lang="scss">
.date-list {
  border-bottom: 1px solid #f0f0f0;
  .date-tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
    background: white;
    padding: 0 12px;
    .date-panel {
      width: calc((100% - 12px) / 3);
      height: 28px;
      line-height: 28px;
      background: #f6f7f8;
      border-radius: 14px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #9798ab;
      text-align: center;
      font-weight: 600;
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }

      &.date-active {
        background: #436eff;
        color: white;
      }
    }
  }
}
</style>
