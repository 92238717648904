<template>
  <div class="data-center-page" ref="scrollDiv">
    <div id="headerBox" ref="headerRef" class="header">
      <div
        :style="{ height: `${appBarHeight}px`, backgroundColor: 'white' }"
      ></div>
      <div class="navBar">
        <img
          @click="handleBack"
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
          alt=""
        />
        <div class="tab-list">
          <span
            v-for="(tab, idx) in tabArr"
            class="tab"
            :key="`tab_${idx}`"
            :class="active === tab.value ? 'active' : ''"
            @click="() => changeTab(tab.value)"
            >{{ tab.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="header-placehold" :style="{ height: `${headHeight}px` }"></div>
    <div class="filter-fixed" :style="{ top: `${headHeight}px` }">
      <!-- 地址网格筛选 -->
      <grid-address
        :businessType="active"
        :addressFilter="addressFilter"
        @confirm="addressConfirm"
      ></grid-address>
      <!-- 时间筛选 -->
      <time-type
        ref="timeTypeRef"
        :dateFilter="dateFilter"
        @change="onTimeTypeChange"
      ></time-type>
    </div>
    <div class="filter-fixed-placehold"></div>
    <div class="date-value">
      <div @click="dateChangeClick">
        <img src="../../assets/app/dataCenter/date@2x.png" />
        <span>{{ dateFilter.dataValue }}</span>
      </div>
    </div>
    <!-- 核心数据 -->
    <core-data
      :currentFilter="currentFilter"
      :dataFilter="dataFilter"
    ></core-data>
    <!-- 图表 -->
    <chart-content
      :currentFilter="currentFilter"
      :dataFilter="dataFilter"
    ></chart-content>
  </div>
</template>

<script>
import {} from '@/api/api';
import moment from 'moment';
import { param2Obj } from '@/utils';
import GridAddress from './components/GridAddress.vue';
import TimeType from './components/TimeType.vue';
import CoreData from './components/CoreData.vue';
import ChartContent from './components/ChartContent.vue';
import { getDateByDate, getLastMonth, getWeekTime } from '../../utils/index';

// 网格默认值
const initGrid = {
  provinceIdx: 0, // 选中省份的下标
  parkCodeList: [], // 选中的网格列表
  city: '全部', // 选中的城市
};
// 日期默认值
const today = new Date();
const lastWeekDay = getDateByDate(today, -7);
const lastMonth = getLastMonth(today);
const initDate = {
  active: 1,
  currentDayDate: today,
  currentWeekDate: getWeekTime(lastWeekDay),
  currentMonth: lastMonth,
  dataValue: moment(today).format('MM月DD日'),
};
export default {
  components: {
    GridAddress,
    TimeType,
    CoreData,
    ChartContent,
  },
  data() {
    return {
      appBarHeight: parseInt(sessionStorage.getItem('_appBarHeight') ?? 0),
      headHeight: 48,
      tabArr: [
        { name: '接驳', value: 'receive' },
        { name: '配送', value: 'delivery' },
      ],
      active: 'receive',
      receiveFilter: {
        dateFilter: initDate,
        addressFilter: initGrid,
      },
      deliveryFilter: {
        dateFilter: initDate,
        addressFilter: initGrid,
      },
      userId: undefined,
    };
  },
  computed: {
    currentFilter() {
      return {
        ...this[`${this.active}Filter`],
        businessType: this.active === 'receive' ? 1 : 2,
      };
    },
    dateFilter() {
      return {
        ...this.currentFilter.dateFilter,
      };
    },
    addressFilter() {
      return {
        ...this.currentFilter.addressFilter,
      };
    },
    // 接口筛选条件
    dataFilter() {
      const timeType = this.dateFilter.active;
      let startTime = undefined,
        endTime = undefined;
      if (timeType === 1) {
        endTime = moment(this.dateFilter.currentDayDate).format('YYYY-MM-DD');
        startTime = moment(endTime).subtract(6, 'days').format('YYYY-MM-DD');
      } else if (timeType === 2) {
        const curLastWeekDay =
          this.dateFilter.currentWeekDate[
            this.dateFilter.currentWeekDate.length - 1
          ];
        startTime = moment(curLastWeekDay)
          .subtract(27, 'days')
          .format('YYYY-MM-DD');
        endTime = moment(curLastWeekDay).format('YYYY-MM-DD');
      } else if (timeType === 3) {
        const monthDate = new Date(this.dateFilter.currentMonth.date);
        startTime = moment(monthDate)
          .subtract(5, 'months')
          .format('YYYY-MM-DD');
        endTime = moment(monthDate).endOf('months').format('YYYY-MM-DD');
      }
      return {
        businessType: this.currentFilter.businessType, // 业务类型 1接驳 2配送
        timeType: timeType, // 时间类型 1日报 2周报 3月报
        startTime: startTime, // 开始时间
        endTime: endTime, // 结束时间
        parkCodeList: this.addressFilter.parkCodeList, // ['010540','010655'] // 网格列表
      };
    },
  },
  watch: {
    dataFilter: {
      handler() {
        // 滚动到顶部
        this.$nextTick(() => {
          let scrollElem = this.$refs.scrollDiv;
          scrollElem.scrollTo({ top: 0, behavior: 'smooth' });
        });
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    const query = param2Obj(location.href);
    this.userId = query.userId;
  },
  mounted() {
    this.headHeight += this.appBarHeight;
    window.getDataCenterFilter = this.getDataCenterFilterMethod;
  },
  destroyed() {},

  methods: {
    handleBack() {
      window.location.href = 'neolix://more';
    },
    changeTab(value) {
      if (this.active === value) return;
      this.active = value;
    },
    addressConfirm(data) {
      this[`${this.active}Filter`].addressFilter = data;
      this.setDataCenterFilterMethod();
    },
    onTimeTypeChange(data) {
      this[`${this.active}Filter`].dateFilter = data;
    },
    dateChangeClick() {
      this.$refs.timeTypeRef.dateChangeClick();
    },
    // 缓存网格筛选条件,通过原生记录,因为localstorage 原生flutter组件不支持
    setDataCenterFilterMethod() {
      const gridFilter = {
        receiveAddress: this.receiveFilter.addressFilter,
        deliveryAddress: this.deliveryFilter.addressFilter,
        userId: this.userId,
      };
      this.utils.setLocalStorageDataByApp(JSON.stringify(gridFilter));
    },
    // 获取缓存的网格筛选条件
    getDataCenterFilterMethod(data) {
      // 优化:当全部筛选返回的结果慢的时候,会把当前筛选条件的覆盖,所以加个定时,筛选条件触发的请求晚一点
      setTimeout(() => {
        this.receiveFilter.addressFilter = data.receiveAddress;
        this.deliveryFilter.addressFilter = data.deliveryAddress;
      }, 300);
    },
  },
};
</script>

<style scoped lang="scss">
.data-center-page {
  position: relative;
  background: #f6f7f8;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .header {
    width: 100vw;
    position: fixed;
    z-index: 3;
    background: #f6f7f8;
    top: 0;
    .navBar {
      height: 48px;
      line-height: 48px;
      position: relative;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      font-weight: bold;
      .tab-list {
        background: white;
      }
      img {
        width: 0.64rem;
        height: 0.64rem;
        position: absolute;
        top: 0.07rem;
        left: 0.22rem;
        z-index: 3;
      }
    }
    .tab {
      width: 2rem;
      text-align: center;
      display: inline-block;
      color: #7a7b92;
      position: relative;
    }
    .active {
      color: #436eff !important;
      position: relative;
      &::after {
        content: '';
        width: 0.84rem;
        height: 0.06rem;
        border-radius: 0.06rem;
        background: #436eff;
        position: absolute;
        bottom: 0.04rem;
        left: 0;
        right: 0;
        margin: auto;
      }
      // span {
      //   display: inline-block;

      // }
    }
  }
  .header-placehold {
    height: 48px;
  }
  .filter-fixed {
    position: fixed;
    height: 98px;
    top: 48px;
    left: 0;
    right: 0;
    background: white;
    z-index: 3;
  }
  .filter-fixed-placehold {
    width: 100%;
    height: 98px;
  }
  .date-value {
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      padding: 0 16px;
      text-align: center;
      background: #dfe5f8;
      border-radius: 12px;
      img {
        width: 14px;
        height: 14px;
        object-fit: cover;
        margin-right: 2px;
      }
      span {
        font-family: PingFangSC-Medium;
        font-size: 12px;
        color: #436eff;
        // letter-spacing: -0.4px;
      }
    }
  }
}
</style>
