/**
 * 周报
 * @ prop data {
 *    isShow: 弹窗展示,
 *    currentWeekDate: 当前选择周,array数组
 * }
 * @ methods {
 *    confirm:日期选中回调
 * }
 */
<template>
  <div class="date-filter">
    <van-calendar
      ref="weekCalendarRef"
      first-day-of-week="1"
      type="multiple"
      v-model="show"
      color="#436EFF"
      :min-date="minDate"
      :max-date="maxDate"
      :show-subtitle="false"
      :show-confirm="false"
      @select="onSelect"
      @unselect="onUnselect"
      @close="onClose"

    >
      <template #title>
        <div class="header">
          <div class="cancel" @click="onClose">取消</div>
          <div class="confirm" @click="onConfirm">确定</div>
        </div>
      </template>
    </van-calendar>
  </div>
</template>

<script>
import moment from 'moment'
import { getWeekTime, getDateByDate} from '../../../utils/index'

export default {
  props: ["isShow",'currentWeekDate'],
  data() {
    return {
      show: this.isShow,
      minDate: new Date(2022, 9, 1),
      maxDate: new Date(),
      selectDate:undefined,
    };
  },
  computed:{

  },
  watch:{
    isShow: {
      handler(newVal) {
        this.show = newVal;
        if(newVal && this.currentWeekDate){
          this.$refs.weekCalendarRef.reset(this.currentWeekDate)
          this.selectDate = this.currentWeekDate
        } 
      },
      immediate:true
    },
  },
  created() {
   
  },

  mounted(){
  },

  methods: {
    formatDate(date) {
      return `${date.getMonth() + 1}月${date.getDate()}日`;
    },
    onSelect(date){
      if(date.length > 0){
        let timeNow = date[date.length-1] // 当前选择的时间
        let nlesson_start = moment().startOf('week')   // 本周开始日期,周日
        let nlesson_end = moment().endOf('week')       // 本周结束日期,周六 
        var testbool = moment(timeNow).isBetween(nlesson_start, nlesson_end, 'time');
        // 本周的日期不可选择
        if(testbool) {
          timeNow = date[date.length-2]
        }
        this.setWeekTimeSelect(timeNow)
      }
    },
    onUnselect(date){
      this.setWeekTimeSelect(date)
    },
    onClose() {
      this.$emit('update:isShow')
    },
    onConfirm() {
      this.$emit("confirm", this.selectDate);
      this.onClose();
    },
    setWeekTimeSelect(date){
      const dates =  getWeekTime(date)
      this.$refs.weekCalendarRef.reset(dates)
      this.selectDate = dates
    }
  },
};
</script>

<style scoped lang="scss">
 .date-filter{
  .header{
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: space-between;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    padding-top: 5px;
    .cancel,.confirm{
      padding: 0 20px;
    }
    .confirm{
      color: #436EFF;
    }
  }
 }
 ::v-deep .van-calendar{
  .van-calendar__header{
    background: #FAFAFA;
    box-shadow: unset;
    border-bottom: 1px solid #EBEBEB;;
  }
  .van-calendar__weekday{
    line-height: 42px;
    font-size: 12px;
    color: #9798AB;
    font-weight: 600;
  }
  .van-calendar__day--multiple-middle{
    background: #E1E8FF !important;
    color: #000000;
  }
  .van-calendar__weekdays,.van-calendar__body{
    padding: 0 9px;
  }
 }
 ::v-deep .van-popup__close-icon--top-right{
  display: none;
 }
</style>
