/** * 网格筛选 * @ prop data { * businessType: 业务类型, * addressFilter: 回显地址筛选条件
* } * @ methods { * confirm:选中回调 * } */
<template>
 <div class="grid-filter"> 
  <div class="grid-filter-box" @click="show = true">
    <div class="address-box">
      <span class="addres ellipsis">{{ city }}</span>
      <span class="triangle"></span>
    </div>
    <div  class="serach-box ellipsis" > 
      <van-field  v-model="title" readonly placeholder="全部网格" input-align="center" />
    </div>
  </div>
  <van-popup
    v-model="show"
    position="bottom"
    round
    @click-overlay="onClose"
    :style="{ height: '500px' }"
  >
  <div class="address-body"> 
    <div class="header"> 
      <div class="header-title">
        <div class="cancel" @click="onClose">取消</div>
        <div class="confirm active-color" :class="{'disabled-color':parkCodeList.length === 0 && provinceIdx !== 0}" @click="onConfirm">确定</div>
      </div>
      <div class="address-title">
        <div class="province ellipsis">省份</div>
        <div class="city ellipsis">城市</div>
        <div class="grid ellipsis">网格<span class="grid-num" v-if="selectParkCodeNum">{{ selectParkCodeNum }}</span></div>
      </div>
    </div>
    <div class="header-placehold"></div>
    <div class="address-content black-color"  >
      <ul :style="{ height: '406px'}">
        <li class="province ">
          <div
            class="cell ellipsis"
            v-for="(province, idx) in location"
            :key="'province_' + idx"
            :class="{'active-color': provinceIdx === idx}"
            @click="provinceClick(province,idx)"

          >
            {{ province.pro }}
          </div>
        </li>
        <li class="city ">
          <div
            class="cell ellipsis"
            v-for="(city, idx) in cityList"
            :key="'city_' + idx"
            :class="{'active-color': cityIdx === idx}"
            @click="cityClick(city,idx)"
          >
            {{ city.city }}
          </div>
        </li>
        <li class="grid ">
          <div
            class="cell ellipsis"
            v-for="(grid, idx) in gridList"
            :key="'grid_' + idx"
            :class="{'active-color': parkCodeList.indexOf(grid.parkCode) > -1}"
            @click="gridClick(grid)"
          >
            {{ grid.parkName }}
          </div>
        </li>
      </ul>
    </div>
  </div>
  </van-popup>

 </div>
</template>

<script>
import { queryProAndCityWithCenter } from '@/api/api';

export default {
  name: "GridAddress",
  props: ['businessType','addressFilter'],
  data() {
    return {
      show:false,
      location: [],
      cityList: [],
      gridList: [],
      provinceIdx:0,      // 选中省份的下标
      cityIdx:0,          // 选中城市的下标
      parkCodeList:[-1],    // 选中的网格列表
      title:'',           // 选择的网格名字  
      city:'全部',         // 选中的城市 
      deliveryOrderType:25,   //准时达运单类型。25:接驳，30:配送
    };
  },
  computed:{
    selectParkCodeNum(){
      if(this.parkCodeList.includes(-1)){
        return this.gridList.length -1
      } else {
        return this.parkCodeList.length
      }
    },
    combinationFilter(){
      return {
        businessType:this.businessType,
        addressFilter:this.addressFilter
      }
    }
  },
  watch:{
    show:{
      handler(newVal){
        if(newVal){
          this.setValueEcho(this.addressFilter)
        }
      },
      immediate:false
    },
    combinationFilter:{
      handler(newVal){
        this.title = this.addressFilter.title
        this.city = this.addressFilter.city
        this.parkCodeList = this.addressFilter.parkCodeList
        this.getGridData()
      },
      immediate:true,
      deep:true
    }
  },
  created() {

  },

  mounted() {

  },

  methods: {
    // 数值回显
    setValueEcho(data){
      this.provinceIdx = data.provinceIdx
      if(this.provinceIdx === 0){
        this.cityList = []
        this.gridList = []
        this.parkCodeList = []
      } else {
        this.cityIdx = data.cityIdx
        const province = this.location[this.provinceIdx];
        const city = province.citys[this.cityIdx];
        this.cityList = province.citys;
        this.gridList = [{parkName:'全部',parkCode:-1},...city.parkList];
        this.parkCodeList = (  !data.title ||  data.title === '全部网格' || data.title.length == 0 )?[-1]:data.parkCodeList
      }
    },
    onClose() {
     this.show = false
    },
    provinceClick(item,idx){
      if(idx === 0){
        this.cityList = []
      } else {
        this.cityList = item.citys;
      }
      this.gridList = []
      this.parkCodeList=[]
      this.provinceIdx = idx
      this.cityIdx = -1
    },
    cityClick(item,idx){
      this.gridList = [{parkName:'全部',parkCode:-1},...item.parkList]; 
      this.parkCodeList=[]
      this.cityIdx = idx
    },
    gridClick(item){
      const idx = this.parkCodeList.findIndex(id=>item.parkCode ===id)
      if(idx > -1){
        // 取消选中
        this.parkCodeList.splice(idx,1);
      } else {
        if(item.parkCode === -1){
          // 选中全部
          this.parkCodeList =[-1]
        } else {
          if(this.parkCodeList.includes(-1) && item.parkCode !== -1 ){
            // 选择其它的时候,如果已选择了全部,需要把全部置灰
            this.parkCodeList = []
          }
          this.parkCodeList.push(item.parkCode)
        }
      }
    },
    onConfirm() {
      if(this.parkCodeList.length === 0 && this.provinceIdx !== 0) return
      if(this.provinceIdx === 0) {
        // 选中全部
        this.title = '全部网格'
        this.city = '全部'
        this.$emit("confirm",{
          provinceIdx:0,      
          parkCodeList: [],
          title:this.title,
          city:'全部',
        });
      } else {
        const {title,arr} = this.getAllParkCodeList()
        this.title = title
        const city = this.cityList[this.cityIdx]
        this.city = city.city
        this.$emit("confirm",{
          provinceIdx:this.provinceIdx,      
          cityIdx:this.cityIdx,   
          parkCodeList: arr,
          title:this.title,
          city:this.city,
        });
      }
      this.onClose()
    },
    // 获取地址信息
    getGridData() {
      queryProAndCityWithCenter({
        deliveryOrderType:this.businessType === 'receive'?25:30
      }).then(res => {
        res = [{pro:'全部'},...res]
        this.location = res
      })
    },
    // 获取当前网格的ParkCodeList和 title
    getAllParkCodeList(){
      let arr = []
      let title = ''
      if(this.parkCodeList.includes(-1)){
        this.gridList.forEach(grid => {
          if(grid.parkCode !== -1){
            arr.push(grid.parkCode)
          }
        })
        title = '全部网格'
      } else {
        arr = this.parkCodeList
        let titleArr = []
        this.parkCodeList.forEach(code => {
          const part = this.gridList.find(item => item.parkCode == code)
          titleArr.push(part.parkName)
        })
        title = titleArr.join('、')
      }
      return {
        title,
        arr
      }
    }
  },
};
</script>

<style scoped lang="scss">
.grid-filter{
  .grid-filter-box {
  display: flex;
  width: 100%;
  height: 44px;
  padding: 12px 12px 0 12px;
  background: white;
  .address-box{
    width: 80px;
    height: 100%;
    background: #F6F7F8;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    padding: 0 5px;
    .addres{
      position: relative;
      font-size: 14px;
      color: #000000;
      text-align: center;
      font-weight: 600;
      margin-right: 2px;
    }
    .triangle{
      margin-top: 5px;
      border: 5px solid transparent;
      border-top-color: black;
    }
  }
  .serach-box{
    flex: 1;
    height: 32px;
    line-height: 32px;
    background: #F6F7F8;
    border-radius: 6px;
    text-align: center;
    font-size: 14px;
    color: black;
    letter-spacing: 0;
    font-weight: 600;
    padding: 0 10px;
    &.init-search-color{
      color: #9798AB;
    }
    ::v-deep .van-cell{
     padding: unset;
     line-height: 32px;
     background: transparent;
     text-align: center;
      input{
        text-overflow:ellipsis;
      }
      ::-webkit-input-placeholder { /* WebKit browsers */ 
        color: #9798ab
      } 
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */ 
        color: #9798ab
      } 
      ::-moz-placeholder { /* Mozilla Firefox 19+ */ 
        color: #9798ab
      } 
      :-ms-input-placeholder { /* Internet Explorer 10+ */ 
        color: #9798ab
      } 
    }
  }
}
}
.address-body{
  display:flex;
  flex-direction: column;
  .header{
    position: fixed;
    width: 100%;
    height: 84px;
    background: #FAFAFA;
    border-bottom: 1px solid #EBEBEB;
    border-radius: 16px 16px 0px 0px;
    .header-title{
      width: 100%;
      height: 46px;
      display: flex;
      justify-content: space-between;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #000000;
      font-weight: 400;
      padding-top: 16px;
      .cancel,.confirm{
        padding: 0 20px;
      }
    }
    .address-title{
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      padding-left: 8px;
      font-size: 14px;
      color: #000000;
      font-weight: 600;
      .grid{
        display: flex;
        align-items: center;
        .grid-num{
          display: flex;
          width: 20px;
          height: 20px;
          align-items: center;
          justify-content: center; 
          font-size: 12px;
          color: #FFFFFF;
          font-weight: 600;
          background: #436EFF;
          border-radius: 50%;
          margin-left: 6px;
        }
      }
    }
  }
  .header-placehold{
    width: 100%;
    height: 84px;
  }
  .address-content{
    flex: 1;
    padding: 10px 5px 0 8px;
    ul {
      width: 100%;
      display: flex;
      font-size: 15px;
      color: gray;
      overflow: hidden;
      li{
        overflow: auto;
        .cell {
          height: 44px;
          line-height: 44px;
          font-size: 14px;
        }
      }
    }
  }
  .province {
    width: 21.8%;
    padding-left: 12px;
  }
  .city {
    width: 32.7%;
    padding-left: 12px;
  }
  .grid {
    flex: 1;
    padding-left: 12px;
    padding-right: 5px;
  }
}
.ellipsis{
  display:block;
  overflow:hidden;
  word-break:keep-all;
  white-space:nowrap;
  text-overflow:ellipsis;
}
.black-color{
  color: black ;
}
.active-color{
  color: #436EFF ;
  font-weight: 600;
}
.disabled-color{
  color: #9EB3FC;;
}

</style>
