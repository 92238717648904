/**
 * 日报
 * @ prop data {
 *    isShow: 弹窗展示,
 *    currentDate: 当前日期
 * }
 * @ methods {
 *    confirm:日期选中回调
 * }
 */
<template>
  <div class="date-filter">
    <van-calendar
      ref="dayCalendarRef"
      v-model="show"
      color="#436EFF"
      first-day-of-week="1"
      :min-date="minDate"
      :max-date="maxDate"
      :show-subtitle="false"
      :show-confirm="false"
      @close="onClose"
      @select="onSelect"
    >
      <template #title>
        <div class="header">
          <div class="cancel" @click="onClose">取消</div>
          <div class="confirm" @click="onConfirm">确定</div>
        </div>
      </template>
    </van-calendar>
  </div>
</template>

<script>

export default {
  props: ["isShow",'currentDate'],
  data() {
    return {
      show: this.isShow,
      minDate: new Date(2022, 9, 1),
      maxDate: new Date(),
      selectDate:undefined,
    };
  },
  watch: {
    isShow: {
      handler(newVal) {
        this.show = newVal;
        if(newVal){
          this.$refs.dayCalendarRef.reset(this.currentDate)
          this.selectDate = this.currentDate
        } 
      },
      immediate:true
    },
  },
  created() {

  },

  mounted(){

  },

  methods: {
    onSelect(date){
      this.selectDate = date
    },
    onClose() {
      this.$emit("update:isShow");
    },
    onConfirm() {

      this.onClose();
      this.$emit("confirm",this.selectDate);
    },
  },
};
</script>

<style scoped lang="scss"> 
 .date-filter{
  .header{
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: space-between;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    padding-top: 5px;
    .cancel,.confirm{
      padding: 0 20px;
    }
    .confirm{
      color: #436EFF;
    }
  }
 }
 ::v-deep .van-calendar{
  .van-calendar__header{
    background: #FAFAFA;
    box-shadow: unset;
    border-bottom: 1px solid #EBEBEB;;
  }
  .van-calendar__weekday{
    line-height: 42px;
    font-size: 12px;
    color: #9798AB;
    font-weight: 600;
  }
  .van-calendar__weekdays,.van-calendar__body{
    padding: 0 9px;
  }
 }
 ::v-deep .van-popup__close-icon--top-right{
  display: none;
 }
</style>
