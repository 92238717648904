/**
 * 名词解释
 * @ prop data { 
  * isShow: 弹窗展示, 
  * key: 当前名词解释的key
* }
*/
<template>
  <van-action-sheet v-model="show" title="名词解释">
    <div class="content">
      <div class="explain-item" v-for="(item,idx) in data" :key="'Glossary_'+idx">
        <div class="explain-item-title">{{ item.title }}</div>
        <div class="explain-item-desc">{{ item.desc }}</div>
      </div>
    </div>
  </van-action-sheet>
</template>

<script>
import { get } from 'http';


export default {
  props: ["isShow","glossaryKey"],
  data() {
    return {
      // show: this.isShow,
      glossary:{
        receive:[
          {
            title: '出车数',
            desc: '统计筛选范围内，所有完成过接驳订单的车辆数（去重）。'
          },
          {
            title: '班次',
            desc: '统计筛选范围内，所有完成的接驳订单总数。'
          },
          {
            title: '单量',
            desc: '统计筛选范围内，所有完成的接驳订单运送货物数量总和。'
          },
        ],
        delivery:[
          {
            title: '出车数',
            desc: '统计筛选范围内，所有完成过配送订单的车辆数（去重）。'
          },
          {
            title: '送件数',
            desc: '统计筛选范围内，所有车辆配送包裹总数（含二次配送）。'
          },
          {
            title: '妥投率',
            desc: '统计筛选范围内，配送并完成签收的包裹总数/送件数*100%。'
          },
        ],
        agingData:[
          {
            title: '响应时长',
            desc: '统计筛选范围内，各任务从任务下发到智能驾驶响应，之间的等待时长。（不含挪车任务）'
          },
          {
            title: '挪车响应时长',
            desc: '统计筛选范围内，各挪车任务从任务下发到智能驾驶响应，之间的等待时长。'
          },
          {
            title: '任务时速',
            desc: '统计筛选范围内，各任务总里程/各任务总时长=单个任务完成的平均速度。（不含挪车任务）'
          },
          {
            title: '平均行驶时速',
            desc: '统计筛选范围内，车辆完成任务时，行驶态（时速>1KM/H）中的平均行驶速度。（不含挪车任务）'
          },
        ],
        mileageData:[
          {
            title: '行驶里程',
            desc: '统计筛选范围内，各车辆完成任务时行驶的累积里程。'
          }
        ],
        adData:[
          {
            title: '行驶里程',
            desc: '统计筛选范围内，所有车辆分别自动驾驶、智能驾驶模式下完成任务行驶的累积里程。'
          },
          {
            title: 'AD启动车数',
            desc: '统计筛选范围内，自动驾驶模式下行驶里程不为0的车辆数（去重）。'
          }
        ]
      },
    };
  },
  computed:{
    data(){
      return  this.glossaryKey ? this.glossary[this.glossaryKey] : []
    },
    show:{
      get:function(){
        return this.isShow || false
      },
      set:function(val){
        this.$emit("update:isShow");
      }
    }
  },
  created() {
  },

  mounted() {},

  methods: {
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 16px 16px 20px;
  .explain-item{ 
    color: #181944;
    margin-bottom: 20px;
    &-title{ 
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    &-desc{ 
      font-size: 14px;
      font-family: PingFangSC-Regular;
      color: #646566;
    }
  }
}
.van-action-sheet__header{
  border-bottom: 1px solid #eef2f4;
}
</style>
