/** * 月报 * @ prop data { * isShow: 弹窗展示, * currentMonthDate: 当前日期 * }
* @ methods { * confirm:日期选中回调 * } */
<template>
  <div class="month-filter">
    <van-popup
      v-model="show"
      position="bottom"
      round
      @click-overlay="onClose"
      :style="{ height: '71.96%' }"
    >
      <div class="month-panel">
        <div class="month-panel-title">
          <div class="cancel" @click="onClose">取消</div>
          <div class="confirm" @click="onConfirm">确认</div>
        </div>
        <div class="month-panel-title-placehold"></div>
        <div class="month-panel-content">
          <div
            class="year"
            v-for="(months, idx) in monthList"
            :key="'year_' + idx"
          >
            <div class="year-title">
              <van-divider>{{ starYear + idx }}年</van-divider>
            </div>
            <div class="months-box">
              <div
                class="month"
                v-for="(month, idx) in months"
                :key="'month_' + idx"
                :class="{
                  'month-disabled-color': month.disabled,
                  'month-active': month.date === currentDate,
                }"
                @click="monthClick(month)"
              >
                {{ month.value }}月
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getLastMonth } from "../../../utils/index";
const today = new Date()
const monthArr = ['一月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','十二月']
export default {
  props: ["isShow", "currentMonthDate"],
  data() {
    return {
      show: this.isShow,
      minDate: new Date(2022, 9, 1),
      maxDate: getLastMonth(today),
      starYear: 2022,           // 日历初始年份从2022开始
      currentDate:undefined,
      currentMonth:undefined,
      monthList: [],
    };
  },
  watch: {
    isShow: {
      handler(newVal) {
        this.show = newVal;
        if(newVal){
          this.currentDate = this.currentMonthDate
        }
      },
    },
  },
  created() {
    this.setMonthList();
  },

  mounted() {},

  methods: {
    monthClick(month) {
      if (month.disabled) return;
      this.currentDate = month.date;
      this.currentMonth = month
    },
    onClose() {
      this.$emit("update:isShow");
    },
    onConfirm() {
      this.onClose();
      this.$emit("confirm", this.currentMonth);
    },
    setMonthList() {
      const today = new Date();
      const curYear = today.getFullYear();
      const curMonth = today.getMonth() + 1;
      for (let i = this.starYear; i <= curYear; i++) {
        let curMonthArr = [];
        for (let j = 1; j < 13; j++) {
          const m = j < 9 ? "0" + j : j
          let month = {
            value: j,
            date: `${i}-${m}`,
            dateFormat: `${i}年${m}月`,
            dateXAxisFormat:`${i}年\n${monthArr[j-1]}`,    // 适配x周坐标显示
          };
          if (
            (i === this.starYear && j > 9) ||
            (i !== this.starYear && i <= curYear && j < curMonth)
          ) {
            month.disabled = false;
          } else {
            month.disabled = true;
          }
          if (i === curYear && j === curMonth - 1) {
            month.isLastMont = true;
          } else {
            month.isLastMont = false;
          }
          curMonthArr.push(month);
        }
        this.monthList.push(curMonthArr);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.month-filter {
  .month-panel {
    position: relative;
    &-title {
      position: fixed;
      width: 100%;
      height: 46px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #000000;
      font-weight: 400;
      background: #FAFAFA;
      border-radius: 16px 16px 0px 0px;
      .cancel,.confirm{
        padding: 0 20px;
      }
      .confirm{
        color: #436EFF;
      }
    }
    &-title-placehold {
      width: 100%;
      height: 42px;
    }
    &-content {
      .year-title {
        width: 50%;
        margin: auto;
        margin-bottom: 20px;
        margin-top: 20px;
 
      }
      .months-box {
        display: flex;
        flex-wrap: wrap;
        padding: 0 9px;
        .month {
          display: flex;
          width: 25%;
          height: 70px;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          color: black;
        }
        .month-disabled-color {
          color: #9798AB;
        }
        .month-active {
          background: #436EFF;
          color: white;
        }
      }
    }
  }
}
.van-divider {
  margin: 0;
  color: black;
  border-color: #d8d8d8;
}
</style>
